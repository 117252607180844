import { Elm } from "../../Main.elm";

function ws_url() {
    let url = new URL("/api/ws", window.location);
    // let url = new URL("/api/ws", "http://127.0.0.1:8080");
    switch (url.protocol) {
        case 'http:':
            url.protocol = 'ws:'
            break;
        case 'https:':
            url.protocol = 'wss:'
            break;
    }
    return url;
}

function error(title, message) {
    console.log(title, message);
}

function connect(url, ports) {
    let socket = new WebSocket(url);

    if (ports.wsSendMessage) {
        ports.wsSendMessage.subscribe(function (message) {
            socket.send(message);
        });
    }

    socket.addEventListener("message", function (event) {
        ports.wsMessageReceiver.send(event.data);
    });
    socket.addEventListener("close", (event) => {
        console.log("websocket closed");
        setTimeout(() => {
            connect(url, ports);
        }, 1000);
    });
}
fetch("/api/config")
    .then(res => res.json())
    .then(
        out => {
            var app = Elm.Main.init({ flags: out })
            if (app.ports.setClipboard) {
                app.ports.setClipboard.subscribe(function (string) {
                    navigator.clipboard.writeText(string).then(
                        () => {
                            if (app.ports.clipboardSuccess) {
                                app.ports.clipboardSuccess.send()
                            }
                        },
                        () => {
                            if (app.ports.clipboardFailure) {
                                app.ports.clipboardFailure.send()
                            }
                        }
                    )
                });
            }

            // Start websocket
            connect(ws_url(), app.ports);

            app.ports.scrollTo.subscribe(function (string) {
                const e = document.getElementById(string);
                if (e) {
                    e.scrollIntoView({ behavior: "smooth" });
                }
            });

            app.ports.showErrorMessage.subscribe(function (e) {
                error(e.title, e.message);
            });
        },
        _ => window.location.replace("/login")
    )