module Main exposing (..)

import Backend.Api as Api
import Browser
import Browser.Navigation as Nav
import Components.CharacterDetail as CharacterDetail
import Components.ThePanel as ThePanel
import Design.AutoComplete as AutoComplete
import Design.Colors as Colors
import Design.Icons as Icons
import Design.Navbar as Navbar exposing (Navbar)
import Dict
import Helper
import Html exposing (Html)
import Html.Attributes exposing (..)
import Html.Events exposing (..)
import Json.Decode as D
import Maybe exposing (withDefault)
import Page.AddCharacterPage as AddCharacterPage
import Page.CharactersPage as CharactersPage
import Page.DashboardPage as DashboardPage
import Page.HuntsPage as HuntsPage
import Page.MyAltsPage as MyAltsPage
import Page.PostLocator as PagePostLocator
import Ports
import Types exposing (AppCtx)
import Url
import Url.Builder
import Url.Parser as Parser exposing ((</>), (<?>))
import Url.Parser.Query as Query


main : Program D.Value Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = UrlChanged
        , onUrlRequest = LinkClicked
        }



-------------------------------------------------------------------------------
-- DATA TYPES
-------------------------------------------------------------------------------


type alias Flags =
    { esi_url : String
    , character_id : Int
    , character_name : String
    , version : String
    }


type Route
    = DashboardPage
    | HuntsPage HuntsPage.Msg
    | CharactersPage CharactersPage.Msg
    | AddCharacterPage
    | PostLocatorPage
    | MyAltsPage
    | Error404Page


type alias Model =
    { key : Nav.Key
    , route : Route
    , flags : Flags
    , shell : { search : AutoComplete.Model }
    , charactersPage : CharactersPage.Model
    , dashboardPage : DashboardPage.Model
    , myAltsPage : MyAltsPage.Model
    , postLocator : PagePostLocator.Model
    , addCharacterPage : AddCharacterPage.Model
    , huntsPage : HuntsPage.Model
    , profile : Navbar.Profile
    , menuItem : Menu
    }


type Msg
    = LinkClicked Browser.UrlRequest
    | UrlChanged Url.Url
    | CharactersPageMsg CharactersPage.Msg
    | DashboardPageMsg DashboardPage.Msg
    | MyAltsPageMsg MyAltsPage.Msg
    | PostLocatorMsg PagePostLocator.Msg
    | AddCharacterPageMsg AddCharacterPage.Msg
    | HuntsPageMsg HuntsPage.Msg
    | ShellSearch AutoComplete.Msg


type Menu
    = Dashboard
    | Hunts
    | Characters
    | Targets
    | AddCharacter
    | PostLocator
    | MyAlts


menuItemFromRoute : Route -> Menu
menuItemFromRoute route =
    case route of
        DashboardPage ->
            Dashboard

        HuntsPage _ ->
            Hunts

        CharactersPage msg ->
            case msg of
                CharactersPage.Show { mode } ->
                    if mode == CharactersPage.TargetsOnly then
                        Targets

                    else
                        Characters

                _ ->
                    Characters

        AddCharacterPage ->
            AddCharacter

        PostLocatorPage ->
            PostLocator

        MyAltsPage ->
            MyAlts

        _ ->
            Dashboard


init : D.Value -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags_ url key =
    let
        route =
            parseRoute url

        flags =
            decodeFlags flags_

        ctx : AppCtx
        ctx =
            { loggedInId = flags.character_id
            , key = key
            }

        profile =
            { name = flags.character_name
            , avatarUrl = Helper.avatar Helper.Small flags.character_id
            }

        shell =
            { search =
                AutoComplete.new
                    "main-search"
                    [ Ports.WithCharacters, Ports.WithCorporations, Ports.WithAlliances, Ports.WithHunts, Ports.WithTags ]
                    AutoComplete.ClearOnSelect
            }

        model =
            { key = key
            , route = route
            , flags = flags
            , shell = shell
            , menuItem = menuItemFromRoute route
            , charactersPage = CharactersPage.new ctx url
            , dashboardPage = DashboardPage.initModel
            , myAltsPage = MyAltsPage.initModel
            , postLocator = PagePostLocator.initModel
            , addCharacterPage = AddCharacterPage.initModel key
            , huntsPage = HuntsPage.initModel ctx url
            , profile = profile
            }
    in
    initCurrentPage url model


initCurrentPage : Url.Url -> Model -> ( Model, Cmd Msg )
initCurrentPage url currentModel =
    let
        ( model, cmd ) =
            case currentModel.route of
                DashboardPage ->
                    let
                        ( n_model, n_cmd ) =
                            DashboardPage.initCurrentPage currentModel.dashboardPage
                    in
                    ( { currentModel | dashboardPage = n_model }, Cmd.map DashboardPageMsg n_cmd )

                CharactersPage m ->
                    let
                        ( n_model, n_cmd ) =
                            CharactersPage.initCurrentPageWithMsg url currentModel.charactersPage m
                    in
                    ( { currentModel | charactersPage = n_model }, Cmd.map CharactersPageMsg n_cmd )

                AddCharacterPage ->
                    let
                        ( n_model, n_cmd ) =
                            AddCharacterPage.initCurrentPage currentModel.addCharacterPage
                    in
                    ( { currentModel | addCharacterPage = n_model }, Cmd.map AddCharacterPageMsg n_cmd )

                PostLocatorPage ->
                    let
                        ( n_model, n_cmd ) =
                            PagePostLocator.initCurrentPage currentModel.postLocator
                    in
                    ( { currentModel | postLocator = n_model }, Cmd.map PostLocatorMsg n_cmd )

                MyAltsPage ->
                    let
                        ( n_model, n_cmd ) =
                            MyAltsPage.initCurrentPage currentModel.myAltsPage
                    in
                    ( { currentModel | myAltsPage = n_model }, Cmd.map MyAltsPageMsg n_cmd )

                HuntsPage m ->
                    let
                        ( n_model, n_cmd ) =
                            HuntsPage.initCurrentPageWithMsg url currentModel.huntsPage m
                    in
                    ( { currentModel | huntsPage = n_model }, Cmd.map HuntsPageMsg n_cmd )

                Error404Page ->
                    ( currentModel, Cmd.none )
    in
    ( model, cmd )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Nav.pushUrl model.key (Url.toString url) )

                Browser.External href ->
                    ( model, Nav.load href )

        -- Parse route upon urlchange
        UrlChanged url ->
            let
                route =
                    parseRoute url

                menuItem =
                    menuItemFromRoute route
            in
            initCurrentPage url { model | route = route, menuItem = menuItem }

        CharactersPageMsg charactersPageMsg ->
            let
                ( n_model, n_cmd ) =
                    CharactersPage.update charactersPageMsg model.charactersPage
            in
            ( { model | charactersPage = n_model }, Cmd.map CharactersPageMsg n_cmd )

        DashboardPageMsg dashboardPageMsg ->
            let
                ( n_model, n_cmd ) =
                    DashboardPage.update dashboardPageMsg model.dashboardPage
            in
            ( { model | dashboardPage = n_model }, Cmd.map DashboardPageMsg n_cmd )

        MyAltsPageMsg myAltsPageMsg ->
            let
                ( n_model, n_cmd ) =
                    MyAltsPage.update myAltsPageMsg model.myAltsPage
            in
            ( { model | myAltsPage = n_model }, Cmd.map MyAltsPageMsg n_cmd )

        PostLocatorMsg postLocatorMsg ->
            let
                ( postLocatorModel, postLocatorCmd ) =
                    PagePostLocator.update postLocatorMsg model.postLocator
            in
            ( { model | postLocator = postLocatorModel }, Cmd.map PostLocatorMsg postLocatorCmd )

        AddCharacterPageMsg addCharacterPageMsg ->
            let
                ( addCharacterPageModel, addCharacterPageCmd ) =
                    AddCharacterPage.update addCharacterPageMsg model.addCharacterPage
            in
            ( { model | addCharacterPage = addCharacterPageModel }, Cmd.map AddCharacterPageMsg addCharacterPageCmd )

        HuntsPageMsg huntsPageMsg ->
            let
                ( huntsPageModel, huntsPageCmd ) =
                    HuntsPage.update huntsPageMsg model.huntsPage
            in
            ( { model | huntsPage = huntsPageModel }, Cmd.map HuntsPageMsg huntsPageCmd )

        ShellSearch acMsg ->
            let
                ( n_model, n_cmd ) =
                    AutoComplete.update acMsg model.shell.search

                buildSearchUrl item =
                    let
                        query =
                            { characters = [], corporations = [], alliances = [], tags = [] }
                    in
                    case item of
                        AutoComplete.ItemSelected (Ports.CharacterItem character) ->
                            Url.Builder.absolute [ "characters" ]
                            [ Url.Builder.string "query" (Api.encodeQuery { query | characters = [ character.name ] })
                            , Url.Builder.int "character" character.id ]

                        AutoComplete.ItemSelected (Ports.CorporationItem corporation) ->
                            Url.Builder.absolute [ "characters" ]
                            [ Url.Builder.string "query" (Api.encodeQuery { query | corporations = [ corporation.name ] }) ]

                        AutoComplete.ItemSelected (Ports.AllianceItem alliance) ->
                            Url.Builder.absolute [ "characters" ]
                            [ Url.Builder.string "query" (Api.encodeQuery { query | alliances = [ alliance.name ] }) ]

                        AutoComplete.ItemSelected (Ports.TagItem tag) ->
                            Url.Builder.absolute [ "characters" ]
                            [ Url.Builder.string "query" (Api.encodeQuery { query | tags = [tag ] }) ]

                        AutoComplete.ItemSelected (Ports.HuntItem hunt ) ->
                            Url.Builder.absolute [ "hunts", String.fromInt hunt.id ]
                            []

                        AutoComplete.NoSelection s ->
                            Url.Builder.absolute [ "characters" ]
                            [ Url.Builder.string "query" (Api.encodeQuery { query | characters = [s] })]
                acCmd =
                    case acMsg of
                        AutoComplete.Selected selection ->
                            buildSearchUrl selection
                                |> Nav.pushUrl model.key

                        _ -> Cmd.none
            in
            ( { model | shell = { search = n_model } }, Cmd.batch [ acCmd, Cmd.map ShellSearch n_cmd ] )


routeParser : Parser.Parser (Route -> a) a
routeParser =
    let
        charactersPage tab charId systemId query page targetOnly =
            let
                mode =
                    if targetOnly == Just True then
                        CharactersPage.TargetsOnly

                    else
                        CharactersPage.All
            in
            CharactersPage (CharactersPage.Show { char = charId, system = systemId, tab = tab, mode = mode, page = page, query = query })
    in
    Parser.oneOf
        [ Parser.map DashboardPage Parser.top
        , Parser.map
            charactersPage
            (Parser.s "characters"
                </> Parser.fragment ThePanel.parseFragment
                <?> Query.int "character"
                <?> Query.int "system"
                <?> Query.string "query"
                <?> Query.int "page"
                <?> Query.enum "targets" (Dict.fromList [ ( "true", True ), ( "false", False ) ])
            )
        , Parser.map
            (\i -> CharactersPage (CharactersPage.Show { char = Just i, system = Nothing, tab = CharacterDetail.RelationsTab, query = Nothing, page = Just 1, mode = CharactersPage.All }))
            (Parser.s "characters" </> Parser.int)
        , Parser.map
            (\i -> CharactersPage (CharactersPage.Show { char = Nothing, system = Just i, tab = CharacterDetail.RelationsTab, query = Nothing, page = Just 1, mode = CharactersPage.All }))
            (Parser.s "system" </> Parser.int)

        -- , Parser.map CharacterDetailPage (Parser.s "characters" </> Parser.int </> Parser.fragment CharactersPage.fragmentToTabs)
        , Parser.map AddCharacterPage (Parser.s "characters" </> Parser.s "new")
        , Parser.map PostLocatorPage (Parser.s "locators" </> Parser.s "add")
        , Parser.map MyAltsPage (Parser.s "alts")

        -- , Parser.map HuntsPage (Parser.s "hunts")
        , Parser.map
            (\hunt tab char system ->
                HuntsPage
                    (HuntsPage.ShowHunt { hunt = hunt, tab = tab, char = char, system = system })
            )
            (Parser.s "hunts"
                </> Parser.int
                </> Parser.fragment ThePanel.parseFragment
                <?> Query.int "character"
                <?> Query.int "system"
            )
        , Parser.map (HuntsPage HuntsPage.ShowForm) (Parser.s "hunts" </> Parser.s "new")
        , Parser.map (HuntsPage HuntsPage.ShowList) (Parser.s "hunts")
        ]


parseRoute : Url.Url -> Route
parseRoute url =
    withDefault errorRoute (Parser.parse routeParser url)


errorRoute : Route
errorRoute =
    Error404Page



-------------------------------------------------------------------------------
-- VIEWS
-------------------------------------------------------------------------------


subscriptions : Model -> Sub Msg
subscriptions model =
    let
        subCmd =
            case model.route of
                CharactersPage _ ->
                    CharactersPage.subscriptions model.charactersPage |> Sub.map CharactersPageMsg

                HuntsPage _ ->
                    HuntsPage.subscriptions model.huntsPage |> Sub.map HuntsPageMsg

                AddCharacterPage ->
                    AddCharacterPage.subscriptions model.addCharacterPage |> Sub.map AddCharacterPageMsg

                _ ->
                    Sub.none
    in
    Sub.batch
        [ subCmd
        , AutoComplete.subscriptions model.shell.search |> Sub.map ShellSearch
        ]


view : Model -> Browser.Document Msg
view model =
    let
        content =
            case model.route of
                CharactersPage _ ->
                    let
                        mcontent =
                            CharactersPage.view model.charactersPage
                                |> Html.map CharactersPageMsg
                    in
                    mcontent

                AddCharacterPage ->
                    let
                        mcontent =
                            AddCharacterPage.view model.addCharacterPage
                                |> Html.map AddCharacterPageMsg
                    in
                    mcontent

                DashboardPage ->
                    -- ( "Dashboard", viewDashboardPage model )
                    let
                        mcontent =
                            DashboardPage.view model.dashboardPage
                                |> Html.map DashboardPageMsg
                    in
                    mcontent

                PostLocatorPage ->
                    let
                        mcontent =
                            PagePostLocator.view model.postLocator
                                |> Html.map PostLocatorMsg
                    in
                    mcontent

                MyAltsPage ->
                    let
                        mcontent =
                            MyAltsPage.view model.myAltsPage
                                |> Html.map MyAltsPageMsg
                    in
                    mcontent

                HuntsPage _ ->
                    let
                        mcontent =
                            HuntsPage.view model.huntsPage
                                |> Html.map HuntsPageMsg
                    in
                    mcontent

                Error404Page ->
                    viewError404Page model
    in
    { title = "Prism"
    , body =
        [ render model content ]
    }


navbar : Model -> Navbar Menu msg
navbar model =
    Navbar.create model.profile
        |> Navbar.items
            [ Navbar.header "Prism" model.flags.version
            , Navbar.nav
                [ Navbar.navItem Dashboard Icons.dashboard "/" "Dashboard"
                , Navbar.navItem Hunts Icons.inboxStack "/hunts" "Hunts"
                , Navbar.navItem Targets Icons.target "/characters?targets=true" "Targets"
                , Navbar.navItem AddCharacter Icons.userAdd "/characters/new" "Add Character"
                , Navbar.navItem Characters Icons.characters "/characters" "All Characters"
                , Navbar.navItem PostLocator Icons.plusCircle "/locators/add" "Post Locator"
                , Navbar.navSplit
                , Navbar.navItem MyAlts Icons.users "/alts" "My Alts"
                ]
            ]
        |> Navbar.select model.menuItem


viewError404Page : Model -> Html Msg
viewError404Page _ =
    Html.div [] []


decodeFlags : D.Value -> Flags
decodeFlags val =
    case D.decodeValue decoderFlags val of
        Ok flags ->
            flags

        Err _ ->
            Flags "" 0 "" ""


decoderFlags : D.Decoder Flags
decoderFlags =
    D.map4 Flags
        (D.field "esi_url" D.string)
        (D.field "character_id" D.int)
        (D.field "character_name" D.string)
        (D.field "version" D.string)


render : Model -> Html Msg -> Html Msg
render model content =
    let
        nav =
            Html.div [ class "row-span-2" ] [ Navbar.render (navbar model) ]

        topbar =
            renderTopbar model
    in
    Html.div
        [ class "flex min-h-screen min-w-screen max-h-screen max-w-screen"
        , class Colors.bgPrimaryClass
        , class Colors.fgPrimaryClass
        ]
        [ nav
        , Html.div [ class "grid grid-cols-1 grid-rows-[64px_auto] w-full" ]
            [ topbar
            , Html.div [ class "grow flex-row overflow-y-auto max-h-[calc(100vh_-_64px)]" ]
                [ content ]
            ]
        ]


renderTopbar : Model -> Html Msg
renderTopbar model =
    Html.div [ class "flex justify-end p-4 border-b w-full", class Colors.borderClass ]
        [ renderSearchbar model
        ]


renderSearchbar : Model -> Html Msg
renderSearchbar model =
    let
        acOpts =
            AutoComplete.defaultViewOpts
    in
    Html.div
        [ class "rounded flex items-center justify-center w-96 px-4 space-between-1 p-2 text-sm bg-gray-900 border border-gray-800"
        ]
        [ Icons.search
        , AutoComplete.view { acOpts | style = AutoComplete.Inline, placeholder = "Search..." } model.shell.search |> Html.map ShellSearch
        ]
